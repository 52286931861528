$primary: #00b5d5;
$primary-lighten: lighten($primary, 3%);
$primary-darken: darken($primary, 3%);
$primary-text-color: #fff;

$gray-medium: #6b727a; 
$gray-light: #e6e6e6;
$gray-ultra-light: #f0f0f0;

$main-typo: 'Open Sans', sans-serif;
$primary-typo: 'Nunito', sans-serif;

$text-color: #888888;
$head-text: #464646; 

$footer-gray: #818181;

$input-bg: #fff;
$input-color: $head-text;
$input-border: #e4e4e4;

$text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);

/** Font awesome **/
$fa-font-path: '/fonts';


/** main z-index **/
$zindex-base: 10;
$zindex-overlay: 999;