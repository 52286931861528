.cards {
    margin-top: 20px;
}

.card {
    display: block;
    border: 1px solid $gray-light;
    margin-bottom: 25px; 
    color: $text-color;

    .image {
        position: relative;

        .date {
            background: $primary;
            position: absolute;
            margin: 25px 30px;
            bottom: 0; 
            color: #fff;
            border-radius: 26px;
            height: 26px;
            line-height: 26px;
            text-transform: uppercase;
            font-weight: bold;
            padding: 0 15px;
            font-size: 0.8rem;
            z-index: 1;
        }
        
        img {
            width: 100%;
        }
    }

    .title, .caption {
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
        white-space: nowrap;
    }

    .content {
        padding: 25px 30px;
    }

    .caption {
        color: $primary;
    }

    .separator {
        height: 1px;
        margin: 25px 0px;
        background-color: $gray-ultra-light;
    }

    .brief {
        max-height: 48px;
        min-height: 48px;
        overflow: hidden;
    }

    .link {
        color: $primary;
        margin-top: 30px;
    }
}

a.card {
    cursor: pointer;
    color: $text-color;

    .image img {
        display: block;
        @include transition(all 0.2s ease-in-out);
        @include filter(grayscale(100%));
        opacity: 0.5;
        @include transform(translate3d(0,0,0))
    }

    &:hover {
        .image img {
            opacity: 1;
            @include filter(none);
            backface-visibility: hidden;
        }
    }
    
    &:hover, &:focus, &:visited {
        color: $text-color;
    }
}