.gallery {
    .gallery-nav {
        img {
            height: auto;
            width: 50%;
            cursor: pointer;
            transition: filter ease-in-out, 0.5s;
            
            &.focused {
                filter: grayscale(100%);
            }
        }
    }
}