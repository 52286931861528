$header-menu-max-height: 600px; 
$header-height: 50px;

.header-nav { 
    height: 100px;
    font-family: $primary-typo;
    font-weight: bold;
    background-color: #fff;

    .logo {
        float: left;
        height: 82px;
        margin: 9px 0px !important;
    }

    nav > ul {
        float: right;
        margin: 25px 0px;
        padding: 0px;
    }
    
    a, .link {
        display: block;
        color: $text-color;
        padding: 0px 15px;
        height: $header-height;
        line-height: $header-height;

        &:hover, &:focus, &:visited {
            text-decoration: none;
            color: $text-color;
        }

        &:hover, &.active {
            color: $primary;
        }
    }

    .link {
        cursor: pointer;
    }
    
    nav >ul > li {
        display: block;
        float: left;
        height: $header-height;
    }
    
    li.has-dropdown {
        & > a, & > .link {
            padding: 1px 16px 0px 16px;
            position: relative;
            z-index: $zindex-base+1;
        }

        &:hover > a, &:hover > .link {
            color: $primary;
            background-color: #fff;
            padding: 0px 15px;
            border: 1px solid $gray-ultra-light;
            border-bottom: none;
        }

        &:hover .dropdown {
            height: auto;
            max-height: $header-menu-max-height;
        }
    }

    .dropdown {
        background-color: #fff;
        font-weight: normal;
        position: absolute;
        z-index: $zindex-base;
        height: 0px;
        max-height: 0px;
        overflow: hidden;
        width: 282px;
        padding: 0px;
        margin: -1px 0 0 0px;
        
        @include transition(max-height 0.3s ease-out);
              
        li {
            display: block;
            border-width: 0px 1px 0px 1px;
            border-color: $gray-ultra-light;
            border-style: solid;
        }

        li:first-child {
            border-top-width: 1px;
        }

        li:last-child{
            border-bottom-width: 1px;
        }
    }

    a, .link {
        color: #999;

        &:hover, &:active {
            color: $primary;
        }
    }

    a.btn {
        color: #fff;
    }

    .hamburger {
        display: none;
    }
    
    .mobile, .menu-opened { display: none; }

@include media-breakpoint-down('lg', $grid-breakpoints) { 
    .mobile { display: block; }

    nav.opened {
        right: 0px;

        .menu-opened { display: block; }
    }
    
    .backdrop.opened, .backdrop.opened-add-active, .backdrop.opened-remove-active  {
        position: fixed;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
    }
    
    .backdrop.opened {
        z-index: $zindex-overlay; 
        opacity: 0.45;
    }
    
    .backdrop {
        width: 0px;
        height: 0px;
        opacity: 0;
        background-color: #000;

        @include transition(opacity 0.3s ease-out);
    }
    
    .link {
        cursor: default;
        color: $text-color !important;   
    }

    nav {
        z-index: $zindex-overlay+1;
        display: block;
        width: 90%;
        min-width: 320px;
        max-width: 400px;
        height: 100%;
        min-height: 100%;
        position: fixed;
        right: -400px;
        top: 0px;
        background-color: #fff;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.42);
        overflow: auto;

        @include transition(right 0.3s ease-out);
        
        >ul {
            float: none;
        }
        
        >ul li {
            display: block;
            float: none;
            height: $header-height;
        }
        
        li.has-dropdown {
            height: auto;

            > a, > a:hover, > .link:hover, > .link {
                padding: 0px 15px;
                border: none !important;
            }

            >ul li {
                padding-left: 30px;
                border: none;
            }
        }

        .dropdown {
            display: block;
            position: relative;
            box-shadow: none;
            height: auto !important;
            max-height: $header-menu-max-height !important;
            width: 100% !important;
            margin: 0px;

            li:last-child::after {
                content: ' ';
                clear: both;
                display: block;
            }
        }
    }
    
    .hamburger, .close {
        display: block;
        position: relative;
        float: right;
        padding: 24px 0 0 0;

        a {
            display: block;
            width: 55px;
            height: 49px;
            padding: 15px;
            border: 1px solid $gray-ultra-light;
            border-radius: 5px;
        }

        span:first-child, span:last-child {
            margin: 0px;
        }

        span {
            display: block;
            width: auto;
            background-color: $text-color;
            height: 3px;
            margin: 5px 0px;
        }

        &:hover span {
            background-color: $primary;
        }
    }

    .close {
        font-size: 3em;
        padding: 15px;

        a {
            
            line-height: 41px; 
            padding: 0px 10px;
        }
    }

    .logo-mobile {
        float: left;
    }

    .meeting {
        border-radius: 0px;
    }
}


/*



    ul {
        padding: 0px;
        display: block;
        margin: 0px;
        
        &.header-nav li {
            float: left;
            margin: 25px 0px;
        }

        .logo {
            float: left;
            height: 82px;
            margin: 9px 0px !important;
        }

        li a {
            display: block;
            padding: 0px 15px;
            height: 50px; 
            line-height: 50px;
        }
    }

    li.submenu { 
        ul {
            display: none;
        }
    }

    li {
        display: block;
        padding: 0px;
    }

    a {
        color: #999;
        border-bottom: none !important;
    }

    a:hover, a.active {
        color: $primary;
    }*/
}