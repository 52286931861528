.contact-cards {
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.6);
    margin-bottom: 50px;
    background-color: #fff;
    
    .item {
        position: relative;
    }
    
    .ccard {
        min-height: 520px;
        background: #fff top center no-repeat;
        background-size: cover;
        width: 100%;
        text-align: center;
        color: #fff;
        margin-bottom: -200px;
        padding-bottom: 200px;

        &:before {
            position: absolute;
            content: ' ';
            height: 100%;
            width: 100%;
            background-color: rgba($primary, 0.8); 
            display: block;
        }

        .content {
            position: relative;
            padding: 60px 50px;
        }

        .title {
            color: #fff;
            font-family: $primary-typo;
            font-weight: bold;
        }

        .btn-white {
            padding: 10px 20px;
            font-weight: bold;
            max-width: 200px;
            width: 100%;
        }

        .descrition {
            margin: 20px 0 30px 0;
        }

        .big-balls {
            background-color: #fff;
            width: 90px;
            height: 90px;
            border-radius: 45px;
            margin: 0 auto 45px auto;
            line-height: 90px;
            box-shadow: 0px 0px 5px rgb(0, 133, 156);
            
            img {
                display: inline-block !important;
                margin: 0px 25px;
                width: 40px;
            }
        }
    }
}

.contact-form {
    margin-bottom: 60px;
    padding: 60px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid $gray-light;
    overflow: hidden;

    .btn-blocks .btn {
        display: block;
        width: 100%;
    }

    .btns-margin .btn {
        margin-bottom: 15px;
    }

    .services-block .btn {
        margin-right: 30px;
        margin-bottom: 15px;
        max-width: 100%;

        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
        white-space: nowrap;
    }

    .textarea {
        height: 125px;
        margin-bottom: 15px;
    }

    .main-label {
        margin: 15px 0px;
    }

    .submit {
        margin-top: 45px;
    }
}

.contact-cancel {
    position: relative;
    top: -80px;
    text-align: center;
    width: 100%;
}