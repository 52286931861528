$carousel-image-ratio: (423 / 1231); 
#head-carousel {
    .item {
        overflow: hidden;
    }

    .main {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
    }

    .blue-container:before { 
        z-index: -1;

        background: rgba($primary,0.8);
        background: -moz-linear-gradient(top,rgba($primary, 0.8) 0%, rgba(darken($primary, 15%), 0.8) 100%);
        background: -webkit-linear-gradient(top, rgba($primary, 0.8) 0%, rgba(darken($primary, 15%), 0.8) 100%);
        background: linear-gradient(to bottom,rgba($primary, 0.8) 0%, rgba(darken($primary, 15%), 0.8) 100%);


        @include transform(skew(-45deg, 0deg));
        content: ' ';
        position: absolute;
        height: 100%; 
        width: 47vw;
        left: -10vw;

        @include media-breakpoint-down('sm', $grid-breakpoints) {
            display: none;
            background: transparent;
        }
    }

    .blue-container {
        height: 100%;
        position: relative;
        color: #fff;
    }

    .content {
        padding-top: calc(100vw * #{$carousel-image-ratio} / 2);
        font-size: 1.3vw;

        margin-top: calc(-1.3vw * 2 - (38px + 0.5rem));
                    //1.3vw => taille du :root
                    //* 2 => deux lignes
                    //38px => talle du bouton
                    // 0.5rem => margin bottom de l'h2

        h2 {
            color: #fff;
            text-shadow: $text-shadow; 
            line-height: 1.2;
            font-weight: bold;
            font-size: 2em;
        }

        .caption {
            text-transform: uppercase;
            font-weight: bold;
        }

        .btn {
            font-weight: bold;
        }


        @include media-breakpoint-down('sm', $grid-breakpoints) {
            text-align: center;
            font-size: 1rem;
            padding-top: 0px;
            margin-top: 120px;
        }
    }

    @include media-breakpoint-down('sm', $grid-breakpoints) {
        img {
            height: 100%;
            max-height: 70vh;
            min-height: 300px;
            width: auto;
            object-fit: cover;
        } 

        .main {
            background: rgba($primary,0.8);
            background: -moz-linear-gradient(top,rgba($primary, 0.8) 0%, rgba(darken($primary, 15%), 0.8) 100%);
            background: -webkit-linear-gradient(top, rgba($primary, 0.8) 0%, rgba(darken($primary, 15%), 0.8) 100%);
            background: linear-gradient(to bottom,rgba($primary, 0.8) 0%, rgba(darken($primary, 15%), 0.8) 100%);
        }
    }



    $prev-next-width: 4vw;
    .next, .prev {
        z-index: 2;
        position: absolute;    
        width: $prev-next-width;
        height: $prev-next-width;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
        background-color: #fff;
        color: $head-text;
        border-radius: 3px;
        cursor: pointer;

        @include media-breakpoint-down('xl', $grid-breakpoints) {
            margin: 0px calc(((100% - #{map-get($container-max-widths, 'xl')}) / 2 - #{$prev-next-width}) / 2);
            margin-top: calc((100vw * #{$carousel-image-ratio} - #{$prev-next-width}) / 2);
        }


        @include media-breakpoint-down('lg', $grid-breakpoints) {
            margin: 0px calc(((100% - #{map-get($container-max-widths, 'lg')}) / 2 - #{$prev-next-width}) / 2);
            margin-top: calc((100vw * #{$carousel-image-ratio} - #{$prev-next-width}) / 2);
        }

        @include media-breakpoint-down('md', $grid-breakpoints) {
            margin: 0px calc(((100% - #{map-get($container-max-widths, 'md')}) / 2 - #{$prev-next-width}) / 2);
            margin-top: calc((100vw * #{$carousel-image-ratio} - #{$prev-next-width}) / 2);
        }

        @include media-breakpoint-down('sm', $grid-breakpoints) {
            display: none;
        }

        &::after { 
            font: normal normal normal 14px/1 FontAwesome;
            display: inline-block;
            line-height: $prev-next-width;
            width: $prev-next-width;
            text-align: center;
        }
    }

    .next {
        right: 0px;
        margin-left: 0px;
        &::after { 
            content: "\f0da";
        }
    }

    .prev {
        left: 0px;
        margin-right: 0px;

        &::after { 
            content: "\f0d9";
        }
    }
} 