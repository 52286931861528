.diff-card {
    border: 1px solid $gray-ultra-light;
    height: 140px;
    margin: 15px 0px;

    .picto {
        height: 100px;
        width: 55px;
        border: 1px solid $gray-ultra-light;
        border-radius: 55px;
        margin: 20px;
        position: relative;
        float: left;
        color: $primary;
        text-align: center;
        font-size: 1.8rem;
        line-height: 100px;
    }

    .content {
        margin-left: 105px; 
        padding: 25px 20px 25px 0px;
    }

    .title {
        font-family: $primary-typo;
        font-weight: bold;
        color: $head-text;
        margin-bottom: 5px;
    }
}