.custom-select {
	$dd-toggle-height: 40px;
	$dd-back-color: #fff;
	$dd-hover-text-color: $head-text;

	position: relative;
	display: block;
    @include user-select(none);
	
	& > select {
		display: none !important;
	}

	& > a.dropdown-toggle {
		display: block;
		padding: $grid-gutter-width / 2;

		&:after {
			display: none;
		}

		> span {
			display: block;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			font-weight: bold;
			line-height: $dd-toggle-height / 2;
		}

		b:before {
			content: "Cliquez pour changer de centre";
			font-weight: normal;
			font-size: 0.8rem;
			line-height: $dd-toggle-height / 2;
			font-family: $primary-typo;
			color: rgba(255, 255, 255, 0.8);
		}
	}

	& > a.dropdown-toggle:before {
		display: block;
		position: relative;
		float : left;
		content: "\f0d7";
		font-family: FontAwesome;
		font-size: 1.5rem;  
		line-height: $dd-toggle-height;
		margin-right: $grid-gutter-width / 2;
	}

	&:hover, &.open {
		background-color: $dd-back-color;
		@include border-radius(3px);

		& > a.dropdown-toggle {
			color: $head-text;
			
			b:before {
				color: $text-color;
			}
		}
	}

	&.open {
		@include border-bottom-radius(0px);
	}

	&.open > a.dropdown-toggle {
		&:before {
			content: "\f0d8";
		}
	}

	.dropdown-menu {
		@include border-top-radius(0px);
		@include border-bottom-radius(3px);
		width: 100%;
		margin: 0px;
		background-color: $dd-back-color;
		border: none;
		padding: 0 $grid-gutter-width / 2;

		.custom-select-search {
			display: none;
		}

		& > ul {
			padding: 0px;
			margin-bottom: 0px;
			list-style: none;
			overflow-x: hidden;
			overflow-y: auto;

			a {
				strong {
					font-weight: normal;
				}
				display: block;
				padding: $grid-gutter-width / 2 0px;
				border-top: 1px inset rgba(0, 0, 0, 0.05);
				color: $text-color;
			}

			a:hover {
				color: $head-text;
			}
		}
	}
}

/**
.custom-select > a.dropdown-toggle.disabled,
.custom-select > a.dropdown-toggle.disabled:hover {
	cursor: not-allowed;
	color: #aaa;
	background: #eee;
}
.custom-select > a.dropdown-toggle:hover, 
.custom-select.open > a.dropdown-toggle {
	color: #333;
}
.control-group.error .custom-select > a.dropdown-toggle {
	border-color: #f09784;
	color: #d68273;
}

.custom-select > .dropdown-menu {
	margin-top: 0;
	border-color: #aaa;
	border-top: 0;
	padding-bottom: 0;
	width: auto;
	min-width: 100%;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	border-radius: 0;
}

*/

/*
.custom-select > .dropdown-menu > .custom-select-search {
	display: none;
}

.custom-select > .dropdown-menu > ul {
	border-color: #aaa;
	border-top: 0;
	margin: 4px 0;
	padding: 0;
	list-style: none;
	background-color: #fff;
	overflow-x: hidden;
	overflow-y: auto;
	max-height: 240px;
	padding-right: 14px;
}
.custom-select > .dropdown-menu > ul > li > a {
	font-size: 13px;
	margin-bottom: 1px;
	margin-top: 1px;
	display: block;
	padding: 3px 8px;
	clear: both;
	font-weight: normal;
	line-height: 20px;
	color: #333;
	cursor: pointer;
	width: 100%;
}
.custom-select > .dropdown-menu > ul > li > a:hover, 
.custom-select > .dropdown-menu > ul > li > a:focus {
	color: #fff;
	text-decoration: none;
	background-repeat: repeat-x;
}
.custom-select > .dropdown-menu > ul > li > a:hover {
	background: #4f99c6;
}
.custom-select > .dropdown-menu > ul > li > a:focus {
	background: #2283c5;
}
.custom-select > .dropdown-menu > ul > li.empty-result > em {
	text-align: center;
	padding: 4px 8px;
	display: block;
}
.custom-select > .dropdown-menu > .custom-select-action > button {
	border-radius: 0;
	background-image: none;
}*/