.suppliers {
    margin-top: 15px;
}

.supplier {
    margin-bottom: 35px;

    .image {
        background-color: $gray-ultra-light;
        padding: 15px;
        
        .contain {
            text-align: center;
            max-height: 200px;
            min-height: 100px;

            overflow: hidden;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center; 
        }
    }

    .name {
        border: 1px solid $gray-light;
        height: 60px;
        line-height: 60px;
        padding: 0px 15px;
    }   
}

a.supplier {
    display: block;
    cursor: pointer;
}