.garanties-container {
    h2 {
        margin: 0px;
        line-height: 80px;
    }

    img {
        width: 80px;
        height: 80px;
    }

    a {
        display: inline-block;
        margin: 0px 47px;
    }

    a:last-child {
        margin-right: 0px;
    }

    @include media-breakpoint-down('lg', $grid-breakpoints) { 
        h2 {
            margin-bottom: 15px;
            line-height: 1;
        }

        a {
            margin: 0px 63px;
        }

        a:first-child {
            margin-left: 0px;
        }
    }

    @include media-breakpoint-down('md', $grid-breakpoints) { 
        a {
            margin: 0px 36px;
        }
    }

    @include media-breakpoint-down('sm', $grid-breakpoints) { 
        a {
            margin: 0 35px 35px 0;
        }
    }
}