
@import "variables"; 

// Bootstrap Grid only
//
// Includes relevant variables and mixins for the regular (non-flexbox) grid
// system, as well as the generated predefined classes (e.g., `.col-4-sm`).


//
// Variables
//

// Buttons
//
// For each of Bootstrap's buttons, define text, background and border color.
/*
$btn-padding-x:                  1rem !default;
$btn-padding-y:                  .5rem !default;
$btn-line-height:                1.25 !default;
$btn-font-weight:                normal !default;*/
$btn-box-shadow:                 inherit;
$btn-active-box-shadow:          inherit;
$brand-primary:                  $primary;

$btn-secondary-color:            $gray-medium;
$btn-secondary-bg:               #fff;
$btn-secondary-border:           $gray-light;

// inputs

$input-bg:                       $input-bg;
$input-color:                    $input-color;
$input-border-color:             $input-border;
$input-border-radius:            3px;
$input-bg-focus:                 $input-bg;
$input-border-focus:             $primary;
$input-color-focus:              $input-color;
$input-color-placeholder:        darken($input-border, 20%);

$input-padding-x-lg:             1.5rem;
$input-padding-y-lg:             1rem;
/*
$input-padding-x:                .75rem !default;
$input-padding-y:                .5rem !default;
$input-padding-x-sm:             .5rem !default;
$input-padding-y-sm:             .25rem !default;
*/

// Grid breakpoints
//
// Define the minimum and maximum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  // Extra small screen / phone
  xs: 0,
  // Small screen / phone
  sm: 544px,
  // Medium screen / tablet
  md: 768px,
  // Large screen / desktop
  lg: 992px,
  // Extra large screen / wide desktop
  xl: 1200px
);


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 576px,
  md: 720px,
  lg: 940px,
  xl: 1140px
);


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:               12;
$grid-gutter-width:          1.875rem; // 30px

// Breadcrumb
$breadcrumb-bg: transparent;

//
// mixins
//
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

/** reboot **/
@import "bootstrap/scss/mixins/hover";
@import "bootstrap/scss/mixins/tab-focus";
@import "bootstrap/scss/normalize";
@import "bootstrap/scss/reboot";

/** grid **/
@import "bootstrap/scss/mixins/clearfix";
@import "bootstrap/scss/mixins/breakpoints";
@import "bootstrap/scss/mixins/grid-framework";
@import "bootstrap/scss/mixins/grid";
@import "bootstrap/scss/grid";

/** nav **/
@import "bootstrap/scss/mixins/border-radius";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";

/** buttons **/
@import "bootstrap/scss/buttons";

/** button group **/
@import "bootstrap/scss/button-group";

/** pagination **/
@import "bootstrap/scss/pagination";

/** forms **/
@import "bootstrap/scss/forms";

/** dropdown **/
@import "bootstrap/scss/dropdown";

/** breadcrumb **/
@import "bootstrap/scss/breadcrumb";


