.f-timesheet {
    padding: 30px 35px;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.15);

    h6 {
        margin-bottom: 0px;
        margin-top: 35px;
        font-family: $main-typo;
        font-weight: bold;
    }

    .comment {
        p:last-child {
            margin-bottom: 0px;
        }
    }

    .email {
        font-size: 0.8em;
    }

    @include media-breakpoint-down('md', $grid-breakpoints) { 
        .phone-title {
            margin: 0px;
        }
    }

    @include media-breakpoint-down('sm', $grid-breakpoints) { 
        .phone-title {
            margin-top: 35px;
        }
    }
}

.facility-rdv {
    padding: 30px 25px;

    .btn {
        width: 100%;
        font-weight: bold;
        padding: 14px 0px;
        line-height: 1;
    }

    .btn-primary {
        margin-bottom: 15px;
    }

    @include media-breakpoint-down('lg', $grid-breakpoints) { 
        margin-bottom: 65px; 

        .btn-primary {
            margin-bottom: 0px;
        }
    }

    @include media-breakpoint-down('sm', $grid-breakpoints) { 
        .btn-primary {
            margin-bottom: 15px;
        }
    }
}

.facility {
    .spaces {
        margin-bottom: 65px;
    }

    .cards {
        padding-top: 45px;

        .service-card {
            margin-bottom: 70px;
        }
    }
}

#facility-map {
    height: 870px;
    width: 100%;
}

.facility-contact {
    position: relative;
    margin-bottom: 430px;

    .contact-cont {
        position: absolute;
        bottom: -370px;
        width: 100%;
        padding: 30px 40px 40px 40px;
        margin-left: -15px;

        h3 {
            margin: 20px 0px 50px 0px;
        }
    }

    .contact-form {
        padding: 30px;

        .form-control {
            margin-bottom: 20px;
        }

        textarea {
            min-height: 230px;
            margin-bottom: 0px !important;
        }
    }

    .btn-primary {
        margin-top: 40px;
    }
}

.facility-container {
    .timesheet {
        margin-top: 30px;
        padding: 35px;

        h5 {
            margin-bottom: 15px;
        }

        .day {
            border-color: $gray-light;
            padding: 5px 0px;
        }
    }
}

.facility-card {
    .brief {
        min-height: 140px;
        max-height: 140px;
    }
}