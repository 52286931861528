.ngdialog.ngdialog-contact {
    min-height: 100%;
    max-height: 100%;
    height: 100%;
    
    .ngdialog-overlay {
        display: none;
    }

    &.ngdialog-closing .ngdialog-content {
        -webkit-animation: ngdialog-flyout .5s;
        animation: ngdialog-flyout .5s;
    }

    .ngdialog-content {
        -webkit-animation: ngdialog-flyin .5s;
        animation: ngdialog-flyin .5s;
    }

    .ngdialog-content, .dialog-main {
        width: 100%;
    }

    .dialog-main {
        background-color: #fff;
    }

    .container {
        z-index: 2;
    }

    .dialog-header {
        position: absolute;
        height: $dialog-contact-header-height;
        width: 100%;
        overflow: hidden;

        .item {
            position: relative;
        }

        .back {
            background: #fff top center no-repeat;
            background-size: cover;
            height: $dialog-contact-header-height;
            width: 100%;

            &:before {
                position: absolute;
                content: ' ';
                height: $dialog-contact-header-height;
                width: 100%;
                background-color: rgba($primary, 0.8); 
                display: block;
            }
        }

        .rect {
            position: absolute;
            bottom: -79%;
            content: ' ';
            height: $dialog-contact-header-height;
            width: 100%;
            @include transform(skew(0deg, -6deg));
            background-color: #fff; 
            display: block;
            z-index: 1;
        }
    }


    .ngdialog-content .container {
        position: relative;
        overflow: hidden;
    }

    .ngdialog-close {
        cursor: pointer;
        position: absolute;
        width: 55px;
        height: 2px;
        padding: 27px 0px;
        right: 50px;
        top: 50px;
        z-index: 99;
        
        &:before, &:after {
            content: ' ';
            display: block;
            position: relative;
            background-color: #fff;
            width: 55px;
            height: 2px;
        }
        &:before {
            @include transform(rotate(45deg));
        }

        &:after {
            @include transform(rotate(-45deg));
            top: -2px;
        }
    }

    .dialog-content-head {
        margin-top: 180px;
        text-align: center;

        h4, h1 {
            color: #fff;
        }

        h4 {
            font-family: $main-typo;
            font-weight: bold;
            margin-top: 25px;
            margin-bottom: 65px;
            height: 24px;
        }

        h1 {
            line-height: 0.7;
        }
    }

    .dialog-button {
        margin-top: 250px;
        
        .btn {
            cursor: pointer;
        }
        
        .btn-circle-full {
            height: 51px;
            width: 51px;
            border-radius: 25.5px;
        }

        .btn span {
            width: 190px;
            display: block;
            color: $gray-medium;
            line-height: 50px;
            font-weight: bold;
            font-size: 0.9rem;
            text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
                
            @include media-breakpoint-down('lg', $grid-breakpoints) {
                width: 150px;
            }
            @include media-breakpoint-down('md', $grid-breakpoints) {
                display: none;
            }
            @include media-breakpoint-down('sm', $grid-breakpoints) {
                display: block;
                width: 180px;
            }
            @include media-breakpoint-down('xs', $grid-breakpoints) {
                display: none;
            }
        }


        @include media-breakpoint-down('sm', $grid-breakpoints) {
            margin-top: 0px;
            margin-bottom: 30px;
        }
    }
}