#footer {
    background: transparent url('/images/footer_pattern.jpg') repeat;
    color: #fff;
    padding-bottom: 70px;

    h5 {
        font-weight: 700;
        color: #fff;
    }

    .separator {
        margin: 70px 0px 55px 0px;
        background-color: rgba(255, 255, 255, 0.25);
        height: 1px;
    }

    a, a:hover, a:visited, a:focus {
        color: #fff;
    }

    .btn-footer {
        background-color: #555A5F;
        border: #555A5F;
        text-shadow: $text-shadow;
        box-shadow: 2px 2px 10px #555A5F;
        font-weight: 700;
        padding: 12px 20px;
    }
    .btn-footer:hover {
        box-shadow: 4px 4px 10px #555A5F;
        background-color: #3d4144;
        border-color: #383b3f;
    }

    .services, .menu {
        ul, li {
            display: block;
            padding: 0px;
        }

        li {
            a:hover::before {
                color: #fff;
            }

            a::before{
                color: $footer-gray;
                content: "\f18e";
                font: normal normal normal 14px/1 FontAwesome;
                display: inline-block;
                margin-right: 15px;
            }
        }
    }


    .timesheet {
        margin-top: 7px;

        .day {
            padding: 5px 0px;
        }
    }

    .custom-select { 
        &:hover, &.open {
            & > a.dropdown-toggle {
                color: $head-text;
                
                b:before {
                    color: $text-color;
                }
            }
        }
	    .dropdown-menu > ul a {
            color: $text-color;
        }
    }
}
