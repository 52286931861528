.service-card {
    display: block;
    cursor: pointer;
    color: $text-color;
    
    .title {
        margin: 25px 0px;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
        white-space: nowrap;
    }

    .image {
        img {
            width: 100%;
            @include transition(all 0.2s ease-in-out);
            opacity: 0.5;
            backface-visibility: hidden;
        }
    }

    .brief {
        display: block; /* Fallback for non-webkit */
        display: -webkit-box;

        min-height: 170px;
        max-height: 170px;
        overflow: hidden;

        -webkit-line-clamp: 7;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
    }

    .link {
        color: $primary;
        margin-top: 25px;
    }

    &:hover {
        .image img {
            opacity: 1;
        }
    }

    &:hover, &:focus, &:visited {
        color: $text-color;
    }
}