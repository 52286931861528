@import "bootstrap";
@import "variables";
@import "font-awesome/scss/font-awesome";
@import "bourbon/app/assets/stylesheets/bourbon";
@import "owl.carousel/src/scss/owl.carousel";
@import "owl.carousel/src/scss/owl.theme.default";
@import "ng-dialog/css/ngDialog";

body {
    font-family: $main-typo;
    color: $text-color;
}

h1, h2, h3, h4, h5, h6 {
    font-family: $primary-typo;
    color: $head-text;
    font-weight: normal;
    
    &.tight {
        line-height: 1;
    }
}

h1 {
    font-weight: bold;
    font-size: 3.0em;
}

h2 {
    font-size: 2.3em;
}

h3 {
    font-size: 1.8em;
}

h4 {
    font-size: 1.3em;
}

h5 {
    font-size: 1.1em;
}

h6 {
    font-size: 1em;
}

a {
    color: $primary;
}

a:hover, a:focus {
    color:darken($primary, 10%);
    text-decoration: none;
}

a:visited {
    text-decoration: none;
}

img.responsive {
    width: 100%;
}

footer { overflow: hidden; }

.back-light-gray {
    background-color: #f5f8fb;
}

.back-white {
    background-color: #fff;
}

.back-primary {
    background-color: $primary;
    color: #f5f8fb;
    
    h1, h2, h3, h4, h5, h6,
    a, a:hover, a:focus, a:visited {
        color: #fff;
    }
}

.breath {
    padding-top: 70px;
    padding-bottom: 70px;
}

.medium-breath {
    padding-top: 35px;
    padding-bottom: 35px;
}

.small-breath {
    padding-top: 25px;
    padding-bottom: 25px;
}

.box-shadow {
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.10);
}

.rounded-sm {
    border-radius: 3px;
    overflow: hidden;
}

.no-margin { margin: 0px !important; }

.text-overflow {
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
    white-space: nowrap;
}

.text-center { text-align: center; }
.text-left { text-align: left; }
.text-right { text-align: right; }
.text-angry { text-transform: uppercase }

.h-100 {
    height: 100%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-transition-delay: 9999s;
    -webkit-transition: color 9999s ease-out, background-color 9999s ease-out;
}

.form-control-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    text-transform: none;
    width: 1px;
}

@include selection {
  background-color: darken($primary, 5%);
  color: #fff;
}

@import "bootstrap-overload";
@import "header-nav";  
@import "hamburger";
@import "card";
@import "garanties";
@import "footer";
@import "copyright";
@import "facility";
@import "service-card";
@import "suppliers";
@import "blog";
@import "head-carousel";
@import "landing";
@import "diff-card";
@import "timesheet";
@import "custom-select";
@import "dialog";
@import "dialog-contact";
@import "contact";
@import "animate";
@import "content-header";
@import "about";
@import "gallery";
