#landing-services {
    .btn {
        font-weight: bold;
        text-shadow: $text-shadow;
        text-transform: uppercase;
        font-size: 0.9rem;
        line-height: 1rem;
    }

    .btn-circle {
        height: 42px;
        width: 42px;
    }
}

#landing-sofa {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#dfecf2+0,dbe8f0+100 */
    background: rgb(223,236,242); /* Old browsers */
    background: -moz-linear-gradient(top,  rgba(223,236,242,1) 0%, rgba(219,232,240,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(223,236,242,1) 0%,rgba(219,232,240,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(223,236,242,1) 0%,rgba(219,232,240,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dfecf2', endColorstr='#dbe8f0',GradientType=0 ); /* IE6-9 */
    
    .btn { font-weight: bold; font-size: 0.9em; }
    .description { margin-bottom: 15px }

    @include media-breakpoint-down('sm', $grid-breakpoints) {
        .description {
            margin: 15px 0px;
        }
    }
}

#landing-quotes {
    color: #fff;
    font-family: $primary-typo;
    font-weight: bold;
    background: transparent url('/images/backquote.jpg') center center no-repeat;
    height: 365px;
    background-size: cover;
    padding: 115px 0px;
    text-align: center;
    font-size: 1.6rem;
    .btn { 
        font-weight: bold; 
        font-size: 0.9rem; 
        color: #fff;
    }
}

#landing-posts {
    .cat {
        margin-top: 15px;
        font-weight: bold;
        font-size: 0.9rem;
    }

    .navigation .btn {
        margin-bottom: 5px;
    }


    @include media-breakpoint-down('sm', $grid-breakpoints) {
        .cat {
            margin-bottom: 35px;
        }
    }
}
